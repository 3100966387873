
@import url('https://fonts.googleapis.com/css2?family=Fira+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";
@import url('https://fonts.cdnfonts.com/css/neue-haas-grotesk-display-pro');
@import url('https://fonts.googleapis.com/css2?family=Geologica:wght@100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Spectral:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,200;1,300;1,400;1,500;1,600;1,700;1,800&display=swap');


@import 'bootstrap/dist/css/bootstrap.min.css';


#Navbar ul:hover {
    color:black;
    transition: ease-in-out 0.3s;
}


#Navbar ul { 
    transition: ease-in-out 0.3s; 
}

.img { 
    object-fit: cover;
    height: 100%;
    width: 100%;
    margin: 0px;
    padding: 0px;
}

/* h1 {
    font-family: 'Geologica', sans-serif;
    font-weight: 500;
    font-size: 5rem;
    letter-spacing: -3px;
    line-height: 9dvb;
} */

h2 {
    /* font-family: 'Neue Haas Grotesk Display Pro', sans-serif; 
    font-weight: 410;
    letter-spacing: 0px;
    font-size: 1.4rem;
    display:block; */
    font-family: 'Geologica', sans-serif;
    font-weight: 500;
    font-size: 1.5rem;
    letter-spacing: 0px;
  }
/* 
  h3 {
    font-family: 'Geologica', sans-serif;
    font-weight: 500;
    font-size: 2.5vw;
    letter-spacing: -3px;
    line-height: 110%;
} */

* {
    margin: 0;
    padding: 0;
}
/* 
h5 {
    font-family: "Spectral", serif;
} */


h1 {
    font-family: 'Neue Haas Grotesk Display Pro', sans-serif;
    font-weight: 600;
    font-style: normal;
    color:black;
    font-size: 4vw;
    line-height: 1;
}

h3 {
    font-family: Helvetica, Arial, sans-serif;
    font-weight: 700;
    font-style: normal;
    font-size: 1.5vw;
    color:black;
    margin: 0;
}

h5 {
    font-family: Helvetica, Arial, sans-serif;
    font-weight: 300;
    font-style: normal;
    color: #6b7280;
    font-size: 1.2vw;

}

h4 {
    font-size: 1.2vw;
    color:black;
}

ul {
    font-family: "Fira Sans", sans-serif;
    font-size: 1vw;
    font-weight: 400;
    font-style: normal;
    color: #6b7280;
}

span {
    font-family: "Fira Sans", sans-serif;
    font-size:1.5vw;
    font-weight: 400;
    font-style: normal;
    color: #2B2C2C;
}

#heavy {
    font-weight: 600;
}


/*border-2 border-black w-fit h-fit px-3 py-2 m-auto hover:bg-black hover:text-white hover:ease-in-out duration-300"*/
button {
   
  

    /* border: 1.5px solid white; */
    width: fit-content;
    height: fit-content;
    /* padding: 0.5rem 2.5rem 0.5rem 2.5rem; */
    font-size: 1.2vw;
    font-size: 1.2vw;
    /* margin: auto; */
    transition: ease-in-out 0.3s;
}

/* button:hover { */
    /* background-color: black;
    border: 1px solid black;
    color: white; */
    /* transition: ease-in-out 0.2s;
} */

button:active{
    transform: scale(0.9);
    transition: ease-in-out 0.2s;
}

button#project {
    color: inherit;
    border: solid inherit
}

.headingHover{
    border: 1px solid white;
    color:white;
    width: fit-content;
    height: fit-content;
    padding: 0.5rem 2.5rem 0.5rem 2.5rem;
    font-size: 1.2vw;
    font-size: 1.2vw;
    margin: auto;
    transition: ease-in-out 0.3s;
}

.headingHover:hover{
    background-color: white;
    color: black;
    font-weight: 430;
    transition: ease-in-out 0.3s;
}

.projectButton{
    font-weight: 600;
    color:white;
}

.projectButton:hover {
    background-color: white;
    color: black;
    font-weight: 430;
    transition: ease-in-out 0.3s;
}

.projectButtonTeam{
    font-weight: 600;
    color:black;
    border: 1px solid black;
}

.projectButtonTeam:hover {
    background-color: black;
    color: white;
    font-weight: 430;
    transition: ease-in-out 0.3s;
}

.arrowButton {
    color: white;
    border: white;
    transition: ease-in-out 0.3s;
}

.arrowButton:hover {
    color: #718096;
    border: #718096;
    transition: ease-in-out 0.3s;
}

#white {
    color: white;
    border: white;
}

/* button:hover {
    background-color: black;
    color:white;
    transition: ease-in-out 0.3s;
} */
/* 
button:hover {
    background-color: none;
} */


/* Bootstrap overrides */


/* .bootstrap-override {
    border-radius: none;
    border: 1px solid red;
} */

.donateButton {
    background: rgba(0,0,0,0.2);
    transition: ease-in-out 0.3s;
}

.donateButton:hover {
    background: rgba(0,0,0,0.5);
    transition: ease-in-out 0.3s;
}

button.dropdown-toggle.btn.btn-primary {
    background-color: white;

    border: none;
    padding: 0;
    margin:0;
    vertical-align: top;
}

.e-caret-hide.dropdown-menu.show{
    width: fit-content;
}

.dropdown-item {
    width: fit-content;
}

.dropdown-item:active {
    background: none;
}

.no-arrow::after {
    display: none !important;
  }


/* .btn-primary {
    background-color: red;
    display: none;
} */


#bootstrap-overrides h1 { /* 100+1=101 priority score, yay! */
    line-height: 1;
    color: inherit;
  }

.desktopHide {
    height:0;
    overflow:hidden;
    background: transparent;
    border: transparent;
    color:transparent;
    box-shadow: none;
    opacity:0;
    cursor:none;
}

.desktopHide:hover {
    height:0;
    overflow:hidden;
    background: none;
    opacity: 0;
    border: transparent;
    color:transparent;
    box-shadow: none;
    cursor:unset
}


  /* animations */

  @keyframes slideLeft {
    from {
      transform: translateX(100%);
      opacity: 0;
    }
    to {
      transform: translateX(0);
      opacity: 1;
    }
  }
  
  /* Apply the animation to the .slide-left class */
  .slide-left {
    animation: slideLeft 0.5s ease-out;
  }

  /*mobile */

  @media only screen and (max-width: 800px) {

#navbar {
    font-size: 2vw;
    color:black;
}

span {
    font-size: 3vw;
    color:inherit;
}

#footer{ 
    font-size: 2.5vw;
    color: white;
}

.mobileHide {
    height:0;
    overflow:hidden;
    background: transparent;
    border: transparent;
    color:transparent;
    box-shadow: none;
    width:0;
    transform: translateY(-100px);
}


.desktopHide {
    height: fit-content;
    overflow:show;
    background: inherit;
    color:inherit;
    border: inherit;
    box-shadow:inherit;
    opacity: inherit
}

.desktopHide:hover {
    height: fit-content;
    overflow:show;
    background: inherit;
    color:inherit;
    border: inherit;
    box-shadow:inherit;
    opacity: inherit
}

.headingHover{
    font-size: 3.5vw;
    color:white;
}

#bg-opacity-40 {
    background-color: rgba(0, 0, 0, 0.4);
    opacity: 0.4;
}



h5 {
    font-size: 2.5vw;
}

h3 {
    font-size: 3.5vw;
    letter-spacing: 0;
}

h1 {
    font-size: 7vw;
}

#donateButton {
    font-size: 2.5vw;
}


.projectButton{
    font-weight: 600;
    font-size: 4vw;
    color:white;
}



.buttonUpscaleMd {
    font-size: 2.5vw;
    color:white;
}


h4 {
    font-size: 3vw;
    color:black;
}


  }


  .dropDown{
    size: 80px;
  }
  

    .dropDown {
      size: 0px;
    }